import './src/main.scss'

import Form from './src/js/form.js';
import SelectForm from './src/js/select.js';

document.addEventListener( 'DOMContentLoaded', event => {
    new Form;
    new SelectForm;
});


